import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import parse from 'html-react-parser';

const ContactIndex = () => {

  const {t, i18n} = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [token, setToken] = useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = (e: any) =>{
    e.preventDefault();
  }

  const url = process.env.REACT_APP_API_URL + "/api/contacts/send";

  const postContact = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const reCaptchaToken = await executeRecaptcha('contact');
    setToken(reCaptchaToken);

    axios.post(url, {
      name: name,
      email: email,
      subject: subject,
      message: message,
      recaptcha_token: token,
    })
    .then(res => {
      if(res.status === 200) {
        console.log('200');
        window.location.href='/contact/complete';
      } else if(res.status === 204) {
        console.log('204');
      } else if(res.status === 500) {
        console.log('500');
      }
    })
    .catch(error => {
      console.log(error);
    })
  }

  return (
      <div className="ContactIndex">
        <section className="frame">
          <div className="title_box">
            <h1>CONTACT<span>お問い合わせ</span></h1>
          </div>
        </section>
        <section className="frame white">
          <div className="contact_box">
            <p>{ t('contactpage.greeting') }</p>
            <form onSubmit={handleSubmit} acceptCharset="UTF-8" id="contact_form">
              <input name="_token" type="hidden" value="kLpqAT8H3rRlI8uiWCmECkcwSD6n0dthlsKKKW2q" />
              <input type="hidden" name="_token" value="kLpqAT8H3rRlI8uiWCmECkcwSD6n0dthlsKKKW2q" />
              <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
              <div className="form-group">
                <label htmlFor="name_label" className="post_name">{ t('contactpage.name') }</label>
                <input className="form-control" id="name_label" name="name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />
              </div>
              <div className="form-group">
                <label htmlFor="email_label" className="post_name">{ t('contactpage.email') }</label>
                <input className="form-control" id="email_label" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </div>
              <div className="form-group">
                <label htmlFor="subject_label" className="post_name">{ t('contactpage.subject') }</label>
                <input className="form-control" id="subject_label" name="subject" type="text" value={subject} onChange={(e) => setSubject(e.target.value)} required />
              </div>
              <div className="form-group">
                <label htmlFor="message_label" className="post_name">{ t('contactpage.message') }</label>
                <textarea className="form-control" id="message_label" name="message" cols={50} rows={10} onChange={(e) => setMessage(e.target.value)} required></textarea>
              </div>
              <input id="one" type="checkbox" required />
                <label htmlFor="one">
                  <span></span>
                  {
                    (() => {
                      if (i18n.language === 'ja') {
                        return (
                          parse('<a href="/privacy">プライバシーポリシー</a>を確認し、同意しました。')
                        );
                      } else {
                        return (
                          parse('I have read and agree to the&nbsp;<a href="/privacy">privacy policy</a>.')
                        );
                      }
                    })()
                  }
                </label>
              <input id="send_button" type="submit" value={ t('contactpage.send') } onClick={postContact} />
            </form>
          </div>
        </section>
      </div>
  );
}

export default ContactIndex;