import About from './About';
import './App.scss';
import Home from "./Home";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Privacy from './Privacy';
import WorkIndex from './WorkIndex';
import WorkDetail from './WorkDetail';
import ContactIndex from './ContactIndex';
import ContactComplete from './ContactComplete';
import Page404 from './Page404';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const App: React.FC = () => {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/works" element={<WorkIndex />} />
          <Route path="/works/:work_id" element={<WorkDetail />} />
          <Route path="/contact" element={
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}>
              <ContactIndex />
            </GoogleReCaptchaProvider>
          } />
          <Route path="/contact/complete" element={<ContactComplete />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;