import { FC, useEffect } from "react"
import { Work } from "../../types/Work";

type Props = {
  activeCategory: any,
  setActiveCategory: any,
  setFilteredWorks: any,
  works: Array<Work>,
}

const Filter: FC<Props> = (
  props: Props,
) => {

  useEffect(() => {
    if (props.activeCategory === 'ALL') {
      props.setFilteredWorks(props.works)
      return;
    }
    const filtered = props.works.filter(work => work.type.includes(props.activeCategory));
    props.setFilteredWorks(filtered);
  }, [props.activeCategory]);

  return (
    <div className="filter-container">
      <button
        className={props.activeCategory === 'ALL' ? 'active' : ''}
        onClick={() => props.setActiveCategory('ALL')}
      >
        🖼 All
      </button>
      <button
        className={props.activeCategory === 'WEB' ? 'active' : ''}
        onClick={() => props.setActiveCategory('WEB')}
      >
        💻 Web
      </button>
      <button
        className={props.activeCategory === 'APPLICATION' ? 'active' : ''}
        onClick={() => props.setActiveCategory('APPLICATION')}
      >
        📱 Mobile Application
      </button>
      <button
        className={props.activeCategory === 'GRAPHIC DESIGN' ? 'active' : ''}
        onClick={() => props.setActiveCategory('GRAPHIC DESIGN')}
      >
        🎨 Graphic Design
      </button>
      <button
        className={props.activeCategory === 'VIDEO' ? 'active' : ''}
        onClick={() => props.setActiveCategory('VIDEO')}
      >
        🎞 Video
      </button>
    </div>
  )
}

export default Filter;