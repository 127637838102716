import { useTranslation } from "react-i18next";

const Footer = () => {

  const {t} = useTranslation();

  return (
    <footer>
      <div className="box">
        &copy;{(new Date).getFullYear()} Hyblinx
      </div>
      <div className="subbox">
        <ul>
          <li><a href="/contact">{ t('contact') }</a></li>
          <li><a href="/privacy">{ t('privacy') }</a></li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;