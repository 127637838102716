import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';

const LanguageButton = () => {

  const { i18n } = useTranslation();
  const languages = {
    ja: '<img src="/img/lang_ja.png" />',
    en: '<img src="/img/lang_en.png" />',
  };

  const languageChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const change = (language: string) => {
    return language === 'ja' ? 'en' : 'ja'
  }

  return (
    <div className="lang_selected">
        {
            <button
                key={change(i18n.language)}
                onClick={() => languageChange(change(i18n.language))}
                disabled={i18n.resolvedLanguage === change(i18n.language)}
            >
                {parse(languages[change(i18n.language)])}
            </button>
        }
    </div>
  );
}

export default LanguageButton;