import { useTranslation } from "react-i18next";

const ContactComplete = () => {

  const {t} = useTranslation();

  const goBack = async () => {
    window.location.href='/';
  }

  return (
    <div className="ContactComplete">
      <section className="frame">
        <div className="contact_box">
          <div className="complete">
            <h5>{ t('contactpage.done_title') }</h5>
            <p>{ t('contactpage.done_text_1') }</p>
            <p>{ t('contactpage.done_text_2') }</p>
            <p>{ t('contactpage.done_text_3') }</p>
            <button onClick={goBack}>{ t('contactpage.done_button') }</button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactComplete;