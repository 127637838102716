import { LatestPost } from "../types/LatestPost";

type Props = {
  latestPost: LatestPost,
}

const NoticeCard = (props: Props) => {

  return (
    <a className="NoticeCard" href={ props.latestPost.url }>
      <div id="notice" className="notice">
        <div className="heading">
          <span>{ props.latestPost.created_at_display }</span>
          <ul>
            <li id="signal01" className="flash"></li>
            <li id="signal02" className="flash"></li>
            <li id="signal03" className="flash"></li>
            <li id="signal04" className="flash"></li>
            <li id="signal05" className="flash"></li>
          </ul>
        </div>
        <p>{ props.latestPost.title }</p>
      </div>
    </a>
  );
}

export default NoticeCard;