import { useTranslation } from 'react-i18next';

const Privacy = () => {

  const {t} = useTranslation();

  return (
    <div className="Privacy">
      <section className="frame">
        <div className="title_box">
          <h1>PRIVACY POLICY<span>プライバシーポリシー</span></h1>
        </div>
      </section>
      <section className="frame white">
        <div className="privacy_box">
          <p>{ t('privacypage.greeting') }</p>
          <h5>1. { t('privacypage.title_1') }</h5>
          <p>{ t('privacypage.text_1') }</p>
          <h5>2. { t('privacypage.title_2') }</h5>
          <p>{ t('privacypage.text_2') }</p>
          <h5>3. { t('privacypage.title_3') }</h5>
          <p>{ t('privacypage.text_3') }</p>
          <h5>4. { t('privacypage.title_4') }</h5>
          <p>{ t('privacypage.text_4') }</p>
          <div className="list">
            <p>{ t('privacypage.text_5') }</p>
            <p>{ t('privacypage.text_6') }</p>
            <p>{ t('privacypage.text_7') }</p>
          </div>
          <h5>5. { t('privacypage.title_5') }</h5>
          <p>{ t('privacypage.text_8') }</p>
          <h5>6. { t('privacypage.title_6') }</h5>
          <p>{ t('privacypage.text_9') }</p>
          <h5>7. { t('privacypage.title_7') }</h5>
          <p>{ t('privacypage.text_10') }</p>
          <h5>8. { t('privacypage.title_8') }</h5>
          <p>{ t('privacypage.text_11') }</p>
          <h5>9. { t('privacypage.title_9') }</h5>
          <p>{ t('privacypage.text_12') }</p>
          <p>{ t('privacypage.text_13') }</p>
          <p>{ t('privacypage.text_14') }</p>
          <h5>10. { t('privacypage.title_10') }</h5>
          <p>{ t('privacypage.text_15') }</p>
        </div>
      </section>
    </div>
  );
}

export default Privacy;