import { motion } from "framer-motion";
import { Work } from "../../types/Work";
import { FC } from "react";

type Props = {
  work: Work,
}

const WorkItem: FC<Props> = (
  props: Props,
) => {
  return (
    <motion.div
      layout
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.35 }}
    >
      <li>
        <a href={'/works/' + props.work.work_id}>
          <img src={props.work.image} />
        </a>
        <div className="during">
          <p>{props.work.worked_at}<span>　{props.work.type}</span></p>
        </div>
        <a href={'/works/' + props.work.work_id} className="title">{props.work.title}</a>
        <p className="type">{props.work.type_detail}</p>
      </li>
    </motion.div>
  );
}

export default WorkItem;