import LanguageButton from "./LanguageButton";

const Header = () => {

  const openNavigation = () => {
    var pBtn = document.getElementById('p-btn');
    if (pBtn) {
      document.getElementById('panel')!.classList.toggle('is-active');
      document.getElementById('p-icon')!.classList.toggle('close');
      document.getElementById('drop_dark')!.classList.toggle('dark');
      document.getElementById('header')!.classList.toggle('in_front');
      document.getElementById('logo')!.classList.toggle('in_background');
      const icons = document.querySelectorAll('.icon');
      icons.forEach (icon => {  
          icon.classList.toggle('open');
      });
    }
  }

  return (
    <header id="header">
      <div className="menu_area">
        <div className="logo" id="logo">
          <a href="/">
            <img src="/img/logo.png" />
          </a>
        </div>
        <div id="panel">
          <a className="menu-logo" href="/">
            <img src="/img/logo.png" />
          </a>
          <ul className="menu">
            <li><a href="/">TOP</a></li>
            <li><a href="/about">ABOUT</a></li>
            <li><a href="/works">WORKS</a></li>
            <li><a href="/contact">CONTACT</a></li>
          </ul>
          <ul className="sns">
            <li><a href="https://twitter.com/ShoHyblinx"><img src="/img/sns_x_trans.png" /></a></li>
            <li><a href="https://github.com/sho-advent"><img src="/img/sns_github_trans.png" /></a></li>
            <li><a href="https://www.linkedin.com/in/sho-advent-71a209226"><img src="/img/sns_linkedin_trans.png" /></a></li>
          </ul>
          <LanguageButton />
        </div>
      </div>
      <div className="sp_menu">
        <a id="p-btn" onClick={openNavigation}>
          <div className="icon" id="p-icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </div>
      <div id="drop_dark"></div>
    </header>
  );
}

export default Header;