import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import Header from './components/Header';
import Footer from './components/Footer';
import ProgressIndicator from './components/ProgressIndicator';

const root = ReactDOM.createRoot(document.getElementById('react-app'));
root.render(
  <>
    <Header />
    <ProgressIndicator />
    <div class="bg_mask">
      <div class="container">
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </div>
    </div>
    <Footer />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
