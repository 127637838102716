import { useEffect, useState } from "react";
import { Autoplay, Pagination } from 'swiper/modules';
import { useParams } from 'react-router-dom'
import axios from "axios";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Work } from "./types/Work";

const WorkDetail = () => {

  const urlParams = useParams();
  const [detail, setDetail] = useState<Work>([] as any);
  const [works, setWorks] = useState<Array<Work>>([]);

  // 先ほど作成したLaravelのAPIのURL
  const url = process.env.REACT_APP_API_URL + "/api/works/" + urlParams.work_id;

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(url);
        setDetail(res.data.detail);
        setWorks(res.data.works);
        return;
      } catch (e) {
        return e;
      }
    })();
  }, [url]);

  return (
    <div className="WorkDetail">
      <section className="frame">
        <div className="title_box">
          <h1>WORK DETAIL<span>制作物紹介</span></h1>
        </div>
      </section>
      <section className="frame white">
        <div className="work_detail_box">
          <ul>
            <li className="img-box">
              {
                (() => {
                  if (detail.url !== null && detail.url !== '') {
                    return (
                      <a href={ detail.url } target="_blank">
                        <img src={ detail.image } alt="画像1" />
                      </a>
                    );
                  } else {
                    return (
                      <img src={ detail.image } alt="画像1" />
                    );
                  }
                })()
              }
            </li>
            <li className="description-box">
              <p className="type">{ detail.type } / { detail.type_detail }</p>
              <h1>{ detail.title }</h1>
              <p>{ detail.description }</p>
              <table>
                <tr>
                  <th>共同作業者</th>
                  <td>{ detail.member_scale }名</td>
                </tr>
                <tr>
                  <th>担当業務</th>
                  <td>{ detail.charge }</td>
                </tr>
                <tr>
                  <th>使用した要素技術</th>
                  <td>{ detail.architecture }</td>
                </tr>
              </table>
            </li>
          </ul>
          <h1 className="other_works">OTHER WORKS</h1>
          <Swiper
            modules={[Autoplay, Pagination]}
            allowTouchMove={ true }
            autoplay={{ delay: 3500, disableOnInteraction: false }}
            breakpoints={{ 500: { slidesPerView: 6 }}}
            loop={ true }
            navigation={{ nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" }}
            slidesPerView={ 3 }
            spaceBetween={ 10 }
          >
              {
                works.map((work) => {
                  return (
                    <SwiperSlide>
                      <a href={'/works/' + work.work_id}>
                        <img src={work.image} />
                      </a>
                    </SwiperSlide>
                  );
                })
              }
          </Swiper>
          <a className="back" href="/works"><i className="bi bi-caret-left-fill"></i>WORKS TOP</a>
        </div>
      </section>
    </div>
  );
}

export default WorkDetail;