import { Link } from "react-router-dom"

const Page404 = () => {
  return (
    <>
      <section className="frame white">
        <div className="privacy_box">
          <h1>404 NOT FOUND</h1>
          <p>お探しのページが見つかりませんでした。</p>
          <Link className='link-button' to='/'>Topに戻る</Link>
        </div>
      </section>
    </>
  )
}

export default Page404;