import { useEffect, useState } from "react";
import axios from "axios";import { Work } from "./types/Work";
import Filter from "./components/Works/Filter";
import { AnimatePresence, motion } from "framer-motion";
import WorkItem from "./components/Works/WorkItem";

const WorkIndex = () => {

  const [works, setWorks] = useState<Array<Work>>([] as any);
  const [filteredWorks, setFilteredWorks] = useState<Array<Work>>([] as any);
  const [activeCategory, setActiveCategory] = useState(0);

  // 先ほど作成したLaravelのAPIのURL
  const url = process.env.REACT_APP_API_URL + "/api/works";

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(url);
        setWorks(res.data.works.data);
        setFilteredWorks(res.data.works.data);
        return;
      } catch (e) {
        return e;
      }
    })();
  }, [url]);

  return (
    <div className="WorkIndex">
      <section className="frame">
        <div className="title_box">
          <h1>WORKS<span>制作物</span></h1>
        </div>
      </section>
      <section className="frame white">
        <div className="work_box">
          <Filter
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            setFilteredWorks={setFilteredWorks}
            works={works}
          />
          <ul>
            <motion.div
              layout 
              className="works"
            >
              <AnimatePresence>
                {
                  filteredWorks.map((work) => {
                    return (
                      <WorkItem
                        key={work.work_id}
                        work={work}
                      />
                    );
                  })
                }
              </AnimatePresence>
            </motion.div>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default WorkIndex;