import { useTranslation } from "react-i18next";

const SkillMap = () => {

  const {t} = useTranslation();

  return (
    <div className="lang_selected">
        {
          <section className="frame">
            <div className="about">
              <div className="new_about">
                <div className="skill animation-scroll-show">
                  <h3>SKILL</h3>
                  <ul>
                    <li>
                      <h4>{ t('skill_header_1') }</h4>
                      <ul>
                        <li>
                          <div className="main-info">
                            <p className="name">Java</p>
                            <p className="time">2{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-40" style={{backgroundColor: '#f29011'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">PHP</p>
                            <p className="time">9{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-80" style={{backgroundColor: '#4f5c90'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">Go</p>
                            <p className="time">0.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-20" style={{backgroundColor: '#2ebcaf'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">HTML/CSS</p>
                            <p className="time">9{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-80" style={{backgroundColor: '#ec6332'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">JavaScript、jQuery</p>
                            <p className="time">8{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-80" style={{backgroundColor: '#efcd38'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">TypeScript</p>
                            <p className="time">3{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-60" style={{backgroundColor: '#3077c6'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">React.js</p>
                            <p className="time">1.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-40" style={{backgroundColor: '#60dbfc'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">Vue.js</p>
                            <p className="time">2.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-40" style={{backgroundColor: '#3fb883'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">Laravel</p>
                            <p className="time">4.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-80" style={{backgroundColor: '#ff2c1f'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">WordPress</p>
                            <p className="time">2.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-60" style={{backgroundColor: '#20749B'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">CakePHP</p>
                            <p className="time">1.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-20" style={{backgroundColor: '#d53c43'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">Zend Framework</p>
                            <p className="time">0.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-40" style={{backgroundColor: '#56ab00'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">Play Framework</p>
                            <p className="time">0.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                              <p className="bar bar-20" style={{backgroundColor: '#92d03c'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">EC-CUBE(4系)</p>
                            <p className="time">0.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-20" style={{backgroundColor: '#f1c501'}}></p>
                          </div>
                        </li>
                      </ul>
                      <h4 className="top-space">{ t('skill_header_2') }</h4>
                      <ul>
                        <li>
                          <div className="main-info">
                            <p className="name">MySQL</p>
                            <p className="time">6{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-60" style={{backgroundColor: '#305a6c'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">Oracle Database</p>
                            <p className="time">1.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-40" style={{backgroundColor: '#e0191f'}}></p>
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <h4>{ t('skill_header_3') }</h4>
                      <ul>
                        <li>
                          <div className="main-info">
                            <p className="name">AWS（ALB + EC2 + RDS、S3、Serverless + DynamoDB）</p>
                            <p className="time">4.5{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-40" style={{backgroundColor: '#f0851d'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">Firebase（Authentication、Cloud Messaging、Hosting）</p>
                            <p className="time">2{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-40" style={{backgroundColor: '#facb2b'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">Docker</p>
                            <p className="time">4{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-40" style={{backgroundColor: '#0092e5'}}></p>
                          </div>
                        </li>
                        <li>
                          <div className="main-info">
                            <p className="name">Terraform</p>
                            <p className="time">0.15{ t('year') }</p>
                          </div>
                          <div className="prog-bar">
                            <p className="bar bar-10" style={{backgroundColor: '#5c41e2'}}></p>
                          </div>
                        </li>
                      </ul>
                      <h4 className="top-space">{ t('skill_header_4') }</h4>
                      <table>
                        <tr>
                          <th>{ t('skill_tool_1') }</th>
                          <td>Mackerel、Datadog、Sentry</td>
                        </tr>
                        <tr>
                          <th>{ t('skill_tool_2') }</th>
                          <td>GitHub Actions</td>
                        </tr>
                        <tr>
                          <th>{ t('skill_tool_3') }</th>
                          <td>Backlog, ClickUp</td>
                        </tr>
                        <tr>
                          <th>{ t('skill_tool_4') }</th>
                          <td>GitHub、BitBuket</td>
                        </tr>
                        <tr>
                          <th>{ t('skill_tool_5') }</th>
                          <td>Esa、DocBase、Notion</td>
                        </tr>
                        <tr>
                          <th>{ t('skill_tool_6') }</th>
                          <td>Slack、Miro</td>
                        </tr>
                        <tr>
                          <th>{ t('skill_tool_7') }</th>
                          <td>VeriTrans、Stripe、KOMOJU</td>
                        </tr>
                        <tr>
                          <th>{ t('skill_tool_8') }</th>
                          <td>commmune、U-KOMI</td>
                        </tr>
                      </table>
                      <h4 className="top-space">{ t('skill_header_5') }</h4>
                      <ul className="summary">
                        <li>{ t('skill_experience_1') }</li>
                        <li>{ t('skill_experience_2') }</li>
                        <li>{ t('skill_experience_3') }</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        }
    </div>
  );
}

export default SkillMap;