import { useEffect } from "react";

const ProgressIndicator = () => {

    const indicatorEnd: Function = (): void => {
        const element = document.getElementById('loading')!;
        if (!element.classList.contains('loaded')) {
            element.classList.add('loaded');
        }
    }

    const isHome = () => {
        return document.getElementById('Home');
    }

    useEffect(() => {
        if (isHome()) {
            setTimeout(indicatorEnd(), 5000);
        } else {
            const element = document.getElementById('loading');
            if (element) {
                element.remove();
            }
        }
    })

    return (
        <div id="loading">
            <img className="indicator_01" src="/img/indicator_frame_01.png" alt="indicator_frame_01" />
            <img className="indicator_02" src="/img/indicator_frame_02.png" alt="indicator_frame_02" />
            <img className="indicator_03" src="/img/indicator_frame_03.png" alt="indicator_frame_03" />
            <img className="indicator_04" src="/img/indicator_frame_04.png" alt="indicator_frame_04" />
            <img className="indicator_05" src="/img/indicator_frame_05.png" alt="indicator_frame_05" />
        </div>
    );
}

export default ProgressIndicator;